import { Box, Grid, Stack, Toolbar, Typography, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Pagination, Tooltip, IconButton, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import { CloudDownloadOutlined, InfoOutlined } from '@mui/icons-material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

function Inventory() {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [rawMaterials, setRawMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.login.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [transferQuantities, setTransferQuantities] = useState({});
  const [restockTransferQuantities, setRestockTransferQuantities] = useState({});
  const [stockTransferQuantities, setStockTransferQuantities] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [storageLocations, setStorageLocations] = useState([]);
  const [cityStorageLocations, setCityStorageLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedCityLocation, setSelectedCityLocation] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [currentTransferMaterial, setCurrentTransferMaterial] = useState(null);
  const [rawMaterialQuantity, setRawMaterialQuantity] = useState([]);
  const [actionType, setActionType] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const getRawMaterialsList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API.BASE_URL}stock-transactions/getOutletCurrentStock?outletId=${outletData._id}&limit=100`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          mainCategory: selectedCategory,
          searchRawMaterial: searchQuery
        }
      }
      );
      const totalRecords = response?.data?.data?.totalRecords;
      setCategories(response?.data?.data?.rawMaterialCategories);
      if (typeof totalRecords === 'string' && totalRecords.includes('No Records')) {
        setRawMaterials([]);
      } else {
        setRawMaterials(totalRecords);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    const title = capitalizeWords('raw material inventory');
    const textWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - textWidth) / 2;
    doc.text(title, titleX, 20);

    doc.setFontSize(10);

    const outletName = capitalizeWords(outletData?.name);
    const outletAddress = capitalizeWords(outletData?.address || 'No Address Available');
    const outletContact = `${outletData.email || 'No Email'}, ${outletData.phone || 'No Phone Info'}`;

    doc.text(`Outlet: ${outletName}`, 14, 30);
    doc.text(`Address: ${outletAddress}`, 14, 40);
    doc.text(`Contact: ${outletContact}`, 14, 50);

    doc.setFontSize(12);

    const startY = 60;

    const columns = [
      { header: capitalizeWords('#'), dataKey: 'itemNo' },
      { header: capitalizeWords('product name'), dataKey: 'productName' },
      { header: capitalizeWords('category'), dataKey: 'category' },
      { header: capitalizeWords('sub category'), dataKey: 'subcategory' },
      { header: capitalizeWords('stock quantity'), dataKey: 'stockQuantity' },
      { header: capitalizeWords('unit'), dataKey: 'unit' },
    ];

    const sortedMaterials = rawMaterials.sort((a, b) => {
      const categoryA = a.rawMaterial.mainCategory.name.toLowerCase();
      const categoryB = b.rawMaterial.mainCategory.name.toLowerCase();
      const subcategoryA = a.rawMaterial.subCategory.name.toLowerCase();
      const subcategoryB = b.rawMaterial.subCategory.name.toLowerCase();

      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      if (subcategoryA < subcategoryB) return -1;
      if (subcategoryA > subcategoryB) return 1;
      return 0;
    });

    const rows = sortedMaterials.map((material, index) => ({
      itemNo: index + 1,
      productName: capitalizeWords(material.rawMaterial.name),
      category: capitalizeWords(material.rawMaterial.mainCategory.name),
      subcategory: capitalizeWords(material.rawMaterial.subCategory.name),
      stockQuantity: parseFloat(material.currentStock.toFixed(2)),
      unit: capitalizeWords(material.rawMaterial.unit),
    }));

    doc.autoTable({
      columns,
      body: rows,
      startY: startY,
      theme: 'grid',
      styles: { halign: 'center' }, // Center align all columns
      columnStyles: {
        0: { cellWidth: 10 }, // Item number column
        1: { cellWidth: 60 }, // Product name column
        2: { cellWidth: 30 }, // Category column
        3: { cellWidth: 30 }, // Subcategory column
        4: { cellWidth: 30 }, // Stock quantity column
        5: { cellWidth: 20 }, // Unit column
      },
    });

    doc.save(`${getCurrentDate()}-Raw-Material-Inventory.pdf`);
  };

  const handleQuantityChange = (rawMaterialId, value) => {
    setTransferQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: value
    }));
  };

  const handleRestockQuantityChange = (rawMaterialId, value) => {
    setRestockTransferQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: value
    }));
  };

  const handleStockQuantityChange = (rawMaterialId, value) => {
    setStockTransferQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: value
    }));
  };

  const handleDebitStockOutletToCentral = async (rawMaterialId, currentStockQuantity) => {
    const transferQuantity = restockTransferQuantities[rawMaterialId] || 0;
    // if (transferQuantity <= 0 || transferQuantity ) {
    //   toast.error("Invalid transfer quantity", {
    //     autoClose: 3000,
    //   });
    //   return;
    // }
    try {
      const response = await axios.post(`${API.BASE_URL}stock-transactions/debit-stock-from-outlet-to-central`,
        {
          "outletId": outletData._id,
          "outletStockTransferData": [
            {
              "rawMaterialId": rawMaterialId,
              "currentStockQuantity": currentStockQuantity,
              "transferQuantity": parseInt(transferQuantity, 10)
            }
          ],
          "storageLocation": selectedLocation
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response?.data?.status === true) {
        toast.success("Central Stock updated successful!", {
          autoClose: 3000,
        });
        getRawMaterialsList();
        // setTransferQuantities({});
        setRestockTransferQuantities({});
      }
    } catch (error) {
      console.error('Error:', error?.response?.data);
      let errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    }
  };

  const getRawMaterialStockCentral = async (rawMaterialId) => {
    try {
      const response = await axios.get(
        `${API.BASE_URL}stock-transactions/getCurrentStock?rawMaterialId=${rawMaterialId}&storageLocation=${selectedLocation}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      console.error("Error:", error?.response?.data);
      let errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
      toast.error(errorMessage, { autoClose: 3000 });
    }
  };

  const handleModalOpen = (material, type) => {
    setCurrentTransferMaterial(material);
    setActionType(type);
    setModalOpen(true);
    setSelectedCity('');
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedLocation('');
  };

  const handleAddStockCentralToOutlet = async (rawMaterialId, currentStockQuantity) => {
    try {
      const response = await getRawMaterialStockCentral(rawMaterialId);
      const currentStockQuantity = response?.data?.currentStock;

      if (!currentStockQuantity) {
        toast.error("Unable to fetch current stock.", { autoClose: 3000 });
        return;
      }

      const transferQuantity = stockTransferQuantities[rawMaterialId] || 0;

      const addStockResponse = await axios.post(`${API.BASE_URL}stock-transactions/addStockToOutlet`,
        {
          outletId: outletData._id,
          outletStockTransferData: [
            {
              rawMaterialId: rawMaterialId,
              currentStockQuantity: currentStockQuantity,
              transferQuantity: parseInt(transferQuantity, 10),
              storageLocation: selectedLocation
            },
          ],
          // storageLocation: selectedLocation
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (addStockResponse?.data?.status === true) {
        toast.success("Outlet Stock updated successfully!", {
          autoClose: 3000,
        });
        getRawMaterialsList();
        setStockTransferQuantities({});
      }
    } catch (error) {
      console.error("Error:", error?.response?.data);
      let errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
      toast.error(errorMessage, { autoClose: 3000 });
    }
  };

  const handleDebitStock = async (rawMaterialId, currentStockQuantity) => {
    const transferQuantity = transferQuantities[rawMaterialId] || 0;
    // if (transferQuantity <= 0 || transferQuantity > currentStockQuantity) {
    //   toast.error("Invalid transfer quantity", {
    //     autoClose: 3000,
    //   });
    //   return;
    // }
    try {
      const response = await axios.post(`${API.BASE_URL}stock-transactions/debitStock`,
        {
          "outletId": outletData._id,
          "outletStockTransferData": [
            {
              "rawMaterialId": rawMaterialId,
              "currentStockQuantity": currentStockQuantity,
              "transferQuantity": transferQuantity
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response?.data?.status === true) {
        toast.success("Stock updated successful!", {
          autoClose: 3000,
        });
        getRawMaterialsList();
        setTransferQuantities({});
      }
    } catch (error) {
      console.error('Error:', error?.response?.data?.data?.message);
      let errorMessage = error?.response?.data?.data?.message || error?.response?.data?.error;
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getRawMaterialsList();
    getCurrentDate();
    console.log('searchQuery', searchQuery);
  }, [searchQuery, selectedCategory]);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    console.log('Selected Category ID:', categoryId);
    setCurrentPage(1);
  };

  const handleSearch = (value) => {
    setSearchQuery(String(value).trim());
  };

  const uniqueCategories = categories?.filter(
    (category, index, self) =>
      index === self.findIndex((c) => c?.mainCategory?._id === category?.mainCategory?._id)
  );

  const pageOffset = (currentPage - 1) * itemsPerPage;

  const paginatedMaterials = rawMaterials.slice(pageOffset, currentPage * itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getStorageLocationCurrentStock = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}storage-location/getCurrentStock`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          "city": selectedCity,
          "rawMaterialId": currentTransferMaterial?.rawMaterial?._id
        }
      });
      console.log("getStorageLocationCurrentStock", response?.data?.data?.storageLocations);
      // setRawMaterialQuantity(response?.data?.data?.storageLocations);
      setCityStorageLocations(response?.data?.data?.storageLocations);
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const getStorageLocation = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}storage-location`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const locations = response?.data?.data?.storageLocations;

      // Group locations by city
      const cityGroups = locations.reduce((acc, loc) => {
        if (!acc[loc.city]) acc[loc.city] = [];
        acc[loc.city].push(loc);
        return acc;
      }, {});
      setStorageLocations(locations);
      setCities(Object.keys(cityGroups));
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    setCityStorageLocations(storageLocations.filter(loc => loc.city === city));
    setSelectedLocation(''); 
  };

  const handleModalConfirm = () => {
    if (!selectedLocation) {
      toast.error("Please select a storage location", { autoClose: 3000 });
      return;
    }
    if (actionType === "add") {
      handleAddStockCentralToOutlet(
        currentTransferMaterial?.rawMaterial?._id,
        currentTransferMaterial?.currentStock
      );
    } else if (actionType === "debit") {
      handleDebitStockOutletToCentral(
        currentTransferMaterial?.rawMaterial?._id,
        currentTransferMaterial?.currentStock
      );
    }
    handleModalClose();
  };

  useEffect(() => {
    getStorageLocation();
  }, [])

  useEffect(() => {
    getStorageLocationCurrentStock();
  }, [selectedCity])

  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container justifyContent={'center'}>
          <Grid item md={11} flexDirection={'column'}>
            <Grid container md={12} justifyContent={'center'} sx={{ marginTop: '35px' }}>
              <Grid item md={12}>
                <Typography sx={{ fontSize: '16px', margin: '16px 0 0 40px', textAlign: 'center' }}>Raw Inventory</Typography>
              </Grid>
              <Grid item md={5}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'darkblue',
                      borderWidth: '2px'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      height: '56px',
                      '&.Mui-focused fieldset': {
                        borderColor: 'darkblue',
                      },
                    },
                    '& input': {
                      height: '10px',
                    },
                  }}
                  placeholder='Search'
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  // onChange={(e) => { handleSearch(e.target.value); }}
                  onChange={(e) => {
                    const query = String(e.target.value);
                    setSearchQuery(query);
                    getRawMaterialsList(query);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <FormControl fullWidth variant="outlined" sx={{
                  backgroundColor: '#fff',
                  marginTop: '5px',
                  marginLeft: '5px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'darkblue',
                    borderWidth: '2px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    '&.Mui-focused fieldset': {
                      borderColor: 'darkblue',
                    },
                  },
                  '& input': {
                    height: '10px',
                  },
                }}>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueCategories?.map((category) => (
                      <MenuItem key={category?.mainCategory?._id} value={category?.mainCategory?._id}>
                        {category?.mainCategory?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item md={2}>
                <FormControl fullWidth variant="outlined"  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    marginLeft: '10px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'darkblue',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: 'darkblue',
                      },
                    },
                    '& input': {
                      height: '10px',
                    },
                  }}>
                  <InputLabel id="category-select-label">Sub Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueCategories?.map((category) => (
                      <MenuItem key={category?.mainCategory?._id} value={category?.mainCategory?._id}>
                        {category?.mainCategory?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>
                <CloudDownloadOutlined onClick={generatePDF} sx={{ fontSize: '45px', cursor: 'pointer', color: '#003049' }} />
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
                <Typography sx={{ paddingLeft: '10px' }}>Fetching Raw Materials</Typography>
              </Box>
            ) : (
              <>
                <Grid container md={userRole !== 'CASHIER' ? 11 : 11} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 20px' }}>
                  {rawMaterials?.length === 0 ? (
                    <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                      No Raw Materials found.
                    </Typography>
                  ) : (
                    <>
                      <Grid item md={userRole !== 'CASHIER' ? 1 : 2} sx={{ padding: '20px 10px 20px 10px', borderRight: '1px solid grey' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>No.</Typography>
                      </Grid>
                      <Grid item md={userRole !== 'CASHIER' ? 3 : 7} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Product Details</Typography>
                      </Grid>
                      <Grid item md={userRole !== 'CASHIER' ? 2 : 3} sx={{ padding: '20px 0px 20px 0px', borderRight: userRole !== 'CASHIER' ? '1px solid grey' : 'none' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>In Stock</Typography>
                      </Grid>
                      {userRole !== 'CASHIER' && (
                        <>
                          <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>
                              Avail. Qty
                              <Tooltip title="Enter the total quantity currently available in outlet." arrow>
                                <IconButton sx={{ marginLeft: '5px', padding: '0' }}>
                                  <InfoOutlined fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </Grid>
                          <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
                              Stock Transfer
                              <Tooltip title="Enter the quantity which needs to be transferred back to the storage location." arrow>
                                <IconButton sx={{ marginLeft: '5px', padding: '0' }}>
                                  <InfoOutlined fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </Grid>
                          <Grid item md={2} sx={{ padding: '20px 0px 20px 0px' }}>
                            <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
                              Add Stock
                              <Tooltip title="Enter the quantity which needs to be transferred to your outlet." arrow>
                                <IconButton sx={{ marginLeft: '5px', padding: '0' }}>
                                  <InfoOutlined fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <>
                        {paginatedMaterials?.length === 0 ? (
                          <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                            No matching products found.
                          </Typography>
                        ) : (
                          paginatedMaterials?.map((material, index) => {
                            const displayIndex = pageOffset + index + 1;
                            const isEvenRow = index % 2 === 0;
                            const backgroundColor = material.stockInfo === "Out of Stock" ? '#FFCDD2' : (isEvenRow ? '#EDF9FF' : '#fff');
                            return (
                              <React.Fragment key={index}>
                                <Grid item md={userRole !== 'CASHIER' ? 1 : 2} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <Typography sx={{ fontSize: '15px' }}>{displayIndex}</Typography>
                                </Grid>
                                <Grid item md={userRole !== 'CASHIER' ? 3 : 7} sx={{ position: 'relative', backgroundColor, padding: '20px 0px', borderRight: '1px solid grey' }}>
                                  <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }} >
                                    <Box sx={{ paddingLeft: '7px' }}>
                                      <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                        {material.rawMaterial.name}
                                      </Typography>
                                      <Typography sx={{ fontSize: '12px', textTransform: 'capitalize' }}>
                                        {material.rawMaterial.mainCategory.name} &gt; {material.rawMaterial.subCategory.name}
                                      </Typography>
                                    </Box>
                                    {material.stockInfo === "Out of Stock" && (
                                      <Box sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'red', color: 'white', fontSize: '10px', padding: '2px 6px', borderRadius: '3px', zIndex: 1, }} >
                                        Out of Stock
                                      </Box>
                                    )}
                                  </Stack>
                                </Grid>
                                <Grid item md={userRole !== 'CASHIER' ? 2 : 3} sx={{ backgroundColor, padding: '20px 10px 20px 10px', borderRight: userRole !== 'CASHIER' ? '1px solid grey' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <Typography sx={{ fontSize: '15px', }}>{material.currentStock.toFixed(2)} {material.rawMaterial.unit}</Typography>
                                </Grid>
                                {userRole !== 'CASHIER' && (
                                  <>
                                    <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', borderRight: '1px solid grey' }}>
                                      <Stack direction={'row'} sx={{ backgroundColor: 'none', display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                          fullWidth
                                          value={transferQuantities[material.rawMaterial._id] || ''}
                                          onChange={(e) => handleQuantityChange(material.rawMaterial._id, e.target.value)}
                                          sx={{
                                            borderRadius: '10px',
                                            backgroundColor: '#fff',
                                            marginTop: '5px',
                                            '& .MuiOutlinedInput-root': {
                                              borderRadius: '10px',
                                            },
                                            '& input': {
                                              height: '10px',
                                            },
                                          }}
                                          variant="outlined"
                                        />
                                        <Box onClick={() => handleDebitStock(material.rawMaterial._id, material.currentStock)} className='cursor-p' sx={{ backgroundColor: '#003049', color: '#fff', padding: '5px', margin: '3px 0 0 10px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                                          <SendIcon />
                                        </Box>
                                      </Stack>
                                    </Grid>
                                    <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', borderRight: '1px solid grey' }}>
                                      <Stack direction={'row'} sx={{ backgroundColor: 'none', display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                          fullWidth
                                          value={restockTransferQuantities[material?.rawMaterial?._id] || ''}
                                          onChange={(e) => handleRestockQuantityChange(material?.rawMaterial?._id, e.target.value)}
                                          sx={{
                                            borderRadius: '10px',
                                            backgroundColor: '#fff',
                                            marginTop: '5px',
                                            '& .MuiOutlinedInput-root': {
                                              borderRadius: '10px',
                                            },
                                            '& input': {
                                              height: '10px',
                                            },
                                          }}
                                          variant="outlined"
                                        />
                                        <Box onClick={() => handleModalOpen(material, "debit")} className='cursor-p' sx={{ backgroundColor: '#003049', color: '#fff', padding: '5px', margin: '3px 0 0 10px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                                          <SendIcon />
                                        </Box>
                                      </Stack>
                                    </Grid>
                                    <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center' }}>
                                      <Stack direction={'row'} sx={{ backgroundColor: 'none', display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                          fullWidth
                                          value={stockTransferQuantities[material?.rawMaterial?._id] || ''}
                                          onChange={(e) => handleStockQuantityChange(material?.rawMaterial?._id, e.target.value)}
                                          sx={{
                                            borderRadius: '10px',
                                            backgroundColor: '#fff',
                                            marginTop: '5px',
                                            '& .MuiOutlinedInput-root': {
                                              borderRadius: '10px',
                                            },
                                            '& input': {
                                              height: '10px',
                                            },
                                          }}
                                          variant="outlined"
                                        />
                                        <Box onClick={() => handleModalOpen(material, "add")} className='cursor-p' sx={{ backgroundColor: '#003049', color: '#fff', padding: '5px', margin: '3px 0 0 10px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                                          <SendIcon />
                                        </Box>
                                      </Stack>
                                    </Grid>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })
                        )}
                      </>
                    </>
                  )}
                </Grid>
                <Grid container justifyContent="center" sx={{ margin: '20px 0' }}>
                  <Pagination
                    count={Math.ceil(rawMaterials.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    shape="rounded"
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: { xs: "90%", sm: 700 },
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              background: "linear-gradient(135deg, #f7f9fc, #ffffff)",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              p: 4,
              borderRadius: "12px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginBottom: 3,
                textAlign: "center",
                fontWeight: "bold",
                color: "#003049",
              }}
            >
              Select Storage Location
            </Typography>

            <Grid container spacing={3}>
              {/* City Selection */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Select a City
                  </Typography>
                  <Select
                    value={selectedCity}
                    onChange={handleCityChange}
                    displayEmpty
                    sx={{
                      bgcolor: "#f1f5f9",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      height: 50,
                      ".MuiSelect-select": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                      "&:hover": {
                        borderColor: "#003049",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Select a city</em>
                    </MenuItem>
                    {cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Storage Location Selection */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginBottom: 1,
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Select a Location
                  </Typography>
                  <Select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    displayEmpty
                    disabled={!selectedCity}
                    sx={{
                      bgcolor: "#f1f5f9",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      height: 50,
                      ".MuiSelect-select": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      },
                      "&:hover": {
                        borderColor: "#003049",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Select a location</em>
                    </MenuItem>
                    {cityStorageLocations?.map((location) => (
                      <MenuItem
                        key={location?._id}
                        value={location?._id}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px 12px",
                          "&:hover": {
                            bgcolor: "#f0f8ff",
                          },
                        }}
                      >
                        <span>{location?.name}</span>
                        {location?.stockInfo?.[0]?.currentStock !== undefined && (
                          <Typography
                            variant="caption"
                            sx={{
                              bgcolor: "#d1e7dd",
                              color: "#155724",
                              padding: "4px 8px",
                              borderRadius: "4px",
                              fontWeight: "bold",
                              fontSize: "0.85rem",
                            }}
                          >
                            Stock: {location.stockInfo[0].currentStock}
                          </Typography>
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 4 }}>
              <Button
                variant="outlined"
                onClick={handleModalClose}
                sx={{
                  flex: 1,
                  marginRight: 2,
                  padding: 1.5,
                  color: "#d32f2f",
                  borderColor: "#d32f2f",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#fddcdc",
                    borderColor: "#c62828",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleModalConfirm}
                disabled={!selectedLocation}
                sx={{
                  flex: 1,
                  marginLeft: 2,
                  padding: 1.5,
                  backgroundColor: "#003049",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#002437",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#b0bec5",
                    color: "#ffffff",
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}

export default Inventory;